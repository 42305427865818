<template>
  <v-container class="pa-0 ma-0 fill-height text-left" fluid>
    <v-row justify="start">
      <v-col cols="12">
        <v-card class="d-flex fill-height fluid pa-0 ma-0" flat tile width="100%">
          <v-scroll-x-transition appear>
            <v-img aspect-ratio="2" max-height="150" src="@/assets/images/doctors_header.jpg"
              transition="slide-x-transition" width="100%">
              <v-container class="fill-height align-end bottom-gradient" fluid>
                <v-row align="center" justify="center">
                  <v-col>
                    <div class="white--text pb-10">
                      <span class="pl-1 pl-md-8 text-h5 text-md-h4 text-uppercase font-weight-black"
                        style="text-shadow: 2px 2px 10px black">
                        Only the best excellence doctors
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-scroll-x-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <div class="boxdivider"></div>
    </v-row>
    <v-row justify="center">
      <SearchBarDoctor :premiums="premiums" :basics="basics" :outstandings="outstanding" :members="members"
        @search="updateFilteredData" />
      <FilterStateMunicipality @filter-data="filterEvent" @filter-data-name="filterEventName" />
    </v-row>
    <v-row justify="center" class="ma-10" v-if="loading">
      <div style="text-align: center;">
        <div>
          Loading our list of excellence doctors, please be patient ...
        </div>
        <div style="display: inline-block;margin-top: -15px;">
          <v-img src="@/assets/images/loading_new.gif" width="100px"></v-img>
        </div>
      </div>
    </v-row>
    <v-row justify="center" class="ma-10"
      v-if="!loading && premiums.length === 0 && outstanding.length === 0 && members.length === 0 && basics.length === 0">
      <div style="text-align: center;">
        <div>
          There are no Doctors in the catalogue...
        </div>
      </div>
    </v-row>
    <v-row v-if="filterPremiums.length !== 0 && !loading" justify="center">
      <p class="text-button"
        style="text-align: center;font-size: 20px !important; font-weight: bold;margin-bottom: -10px;">Accredited
        Doctors of Excellence of Baja
        California by COEPRIS and REPSSABI</p>
    </v-row>
    <v-row align="center" justify="center" v-if="!loading">
      <div class="fill-height container--fluid mb-8 mx-8" style="width: 100%">
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="start" style="padding: 30px">
            <v-col v-for="(item, index) in filterPremiums" :key="index" class="d-flex child-flex mb-5" cols="12" sm="6"
              md="3">
              <CardDoctor :item="item" />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>

    <v-row justify="center" v-if="filterOutstanding.length !== 0 && !loading">
      <p class="text-button" style="font-size: 22px !important; font-weight: bold;margin-bottom: -10px;">List of
        Outstanding Doctors</p>
    </v-row>
    <v-row align="center" justify="center" v-if="filterOutstanding.length > 0 && !loading">
      <div class="fill-height container--fluid mb-8 mx-8" style="width: 100%">
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="start" style="padding: 30px">
            <v-col v-for="(item, index) in filterOutstanding" :key="index" class="d-flex child-flex mb-5" cols="12"
              sm="6" md="3">
              <CardDoctor :item="item" />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>

    <v-row justify="center" v-if="filterMembers.length !== 0 && !loading">
      <p class="text-button" style="font-size: 22px !important; font-weight: bold;margin-bottom: -10px;">List of
        Member Doctors</p>
    </v-row>
    <v-row align="center" justify="center" v-if="filterMembers.length > 0 && !loading">
      <div class="fill-height container--fluid mb-8 mx-8" style="width: 100%">
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="start" style="padding: 30px">
            <v-col v-for="(item, index) in filterMembers" :key="index" class="d-flex child-flex mb-5" cols="12" sm="6"
              md="3">
              <CardDoctor :item="item" />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>

    <v-row justify="center" v-if="filterBasics.length !== 0 && !loading">
      <p class="text-button" style="font-size: 22px !important; font-weight: bold;margin-bottom: -10px;">List of
        Basic Doctors</p>
    </v-row>
    <v-row align="center" justify="center" v-if="filterBasics.length > 0 && !loading">
      <div class="fill-height container--fluid mb-8 mx-8" style="width: 100%">
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="start" style="padding: 30px">
            <v-col v-for="(item, index) in filterBasics" :key="index" class="d-flex child-flex mb-5" cols="12" sm="6"
              md="3">
              <CardDoctor :item="item" />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import doctorsService from "@/providers/DoctorsService";
import SearchBarDoctor from "@/components/searchBar/SearchBarDoctor";
import CardDoctor from "@/components/card-doctor/CardDoctor";
import FilterStateMunicipality from "@/components/filter-state-municipality/FilterStateMunicipality";

export default {
  name: "DoctorsComponent",

  components: {
    SearchBarDoctor,
    CardDoctor,
    FilterStateMunicipality
  },

  props: {
    unique: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    premiums: [],
    basics: [],
    members: [],
    outstanding: [],
    filterPremiums: [],
    loadingPremiums: true,
    filterBasics: [],
    loadingBasics: true,
    filterMembers: [],
    filterOutstanding: [],
    loadingOutstanding: true,
    loadingMembers: true,
    transparent: "rgba(255, 255, 255, 0)",
    message: "Loading our list of excellence doctors, please be patient ...",
  }),

  watch: {
    filteredData(value) {
      this.visibleCards = value;
    },
  },

  methods: {
    filterEventName(value){
      let s = "";
      if(value.province){
        s = `${value.province}, México `
      }
      if (value.municipality){
        s = `${value.municipality}, ${s}`
      }
      if(s !== ""){
        s = `of ${s}`;
      }
      document.title = `Top 10 Doctors ${s}| Baja California Health Tourism`;
    },
    filterEvent(value){
      this.getDoctors(value.province, value.municipality);
    },
    isFechaVencida(fecha) {
      const fechaVencimiento = fecha.split("T")[0];
      const fechaActual = new Date().toISOString().split("T")[0];
      return fechaVencimiento > fechaActual;
    },

    getDoctors(city='', municipality='') {
      this.loadingBasics = true;
      this.loadingOutstanding = true;
      this.loadingPremiums = true;
      this.loadingMembers = true;

      doctorsService.getViewListByNumber(this.unique, false, true, 'P', city, municipality).then((record) => {
        this.premiums = [...record.value];
        this.filterPremiums = [...record.value];
      }).catch(err=>console.log(err)).finally(()=>{
        this.loadingPremiums = false;
      });

      doctorsService.getViewListByNumber(this.unique, false, true, 'O', city, municipality).then((record) => {
        this.outstanding = [...record.value];
        this.filterOutstanding = [...record.value];
      }).catch(err => console.log(err)).finally(() => {
        this.loadingOutstanding = false;
      });

      doctorsService.getViewListByNumber(this.unique, false, true, 'M', city, municipality).then((record) => {
        this.members = [...record.value];
        this.filterMembers = [...record.value];
      }).catch(err => console.log(err)).finally(() => {
        this.loadingMembers = false;
      });

      doctorsService.getViewListByNumber(this.unique, false, true, 'B', city, municipality).then((record) => {
        this.basics = [...record.value];
        this.filterBasics = [...record.value];
      }).catch(err => console.log(err)).finally(() => {
        this.loadingBasics = false;
      });
    },

    updateFilteredData(data) {
      this.filterPremiums = data.premiums;
      this.filterBasics = data.basics;
      this.filterMembers = data.members;
      this.filterOutstanding = data.outstandings;
    },

    handleScroll() {
      this.itemsPerPage += 1
      if (this.itemsPerPage >= 30) {
        this.membersVisibles = true
      }
    }
  },
  computed: {
    loading(){
      return this.loadingBasics || this.loadingMembers || this.loadingOutstanding || this.loadingPremiums;
    }
  },

  mounted() {
    this.premiums = [];
    this.members = [];
    this.outstanding = [];
    this.basics = [];
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.bottom-gradient {
  background-image: linear-gradient(
      to bottom,
      rgba(116, 148, 153, 0.29) 0%,
      rgba(15, 18, 21, 0.7) 100%
  );
  opacity: 1;
  background-color: transparent;
  background-blend-mode: normal;
}

#my-lax .v-parallax__content {
  background: linear-gradient(45deg, black, transparent);
}

.v-btn {
  text-transform: none !important;
}

.blend-opacity {
  opacity: 0.5;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: normal;
}

/* .blend-opacity:hover {
         opacity: 0.9;
         transition: opacity 0.4s ease-in-out;
         background-color: black;
         background-blend-mode: darken;
     }*/
.boxdivider {
  background-color: transparent;
  width: 100px;
  height: 50px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}

.d-none {
  display: none !important;
}
</style>
