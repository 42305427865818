<template>
    <v-card hover class="rounded-lg card-doctor-custom">
        <div style="cursor: pointer; height: 300px; overflow: hidden" v-on:click="showProfile(item.slu)">
            <v-img :lazy-src="require('@/assets/images/no-picture.jpg')" :src="item.pictures_portal[0] != null
                    ? item.pictures_portal[0].url
                    : require('@/assets/images/no-picture.jpg')
                " class="rounded-t-lg" position="top" style="object-fit: cover">
                <template v-slot:placeholder>
                    <v-row align="center" class="fill-height ma-0" justify="center">
                        <v-progress-circular color="grey lighten-5" indeterminate>
                        </v-progress-circular>
                    </v-row>
                </template>
            </v-img>
            <img v-if="item.typeEntity == 'P'" src="@/assets/images/icons/premium.png" alt="sello"
                class="superior-derecha" title="Premium" />
            <img v-else-if="item.typeEntity == 'M'" src="@/assets/images/icons/member.png" alt="sello"
                class="superior-derecha" title="Member"/>
            <img v-else-if="item.typeEntity == 'B'" src="@/assets/images/icons/basic.png" alt="sello"
                class="superior-derecha" title="Basic"/>
            <img v-if="item.excellence === 1" src="@/assets/images/icons/escudo_coepris.f8331f99.png" alt="sello1"
                class="superior-derecha-segunda" title="Excellence"/>
            <img v-if="item.coparmex === 1" src="@/assets/images/icons/coparmex.png" alt="sello1"
                class="superior-derecha-tercera" title="Coparmex"/>
            <img v-if="item.certified_surgeon === 1" src="@/assets/images/icons/surgeon.jpg" alt="sello1"
                class="superior-derecha-cuarta" title="Certified surgeon"/>
            <img v-if="item.promoted === 1" src="@/assets/images/icons/promoted.png" alt="sello1" class="top-left" title="Promoted"/>
            <img v-if="item.repsabi === 1" src="@/assets/images/icons/repssabi.png" alt="sello1" title="Repssabi"
                class="top-left-second" />
        </div>
        <v-btn class="mx-0 mt-1" elevation="0" plain text @click="showProfile(item.slu)">
            <span class="pb-0 indigo--text text-subtitle-2 text-truncate"> {{ formatName }}</span>
        </v-btn>

        <div class="row pt-0 mt-0">
            <div class="col-12">
                <v-card-subtitle class="pb-0 pt-0 text-subtitle-2 indigo--text text-capitalize text-truncate">
                    <router-link :to="`/specialty-profile/${item.specialties_portal[0]?.id}`">
                        <span v-if="item.specialties_portal[0] != null">
                            {{
                            item.specialties_portal[0].name_english
                            ? item.specialties_portal[0].name_english
                            : item.specialties_portal[0].name
                            }}
                        </span>
                        <span v-else> Not assigned </span>
                    </router-link>
                </v-card-subtitle>
            </div>
        </div>
        <v-card-actions class="mt-1 justify-center action-socials">
            <v-btn v-if="item.facebook_profile !== null" :href="item.facebook_profile" color="blue" icon
                target="_blank">
                <v-icon color="primary" size="24px">mdi-facebook
                </v-icon>
            </v-btn>
            <v-btn v-if="item.instagram_profile !== null" :href="item.instagram_profile" color="#D93F8FFF" icon
                target="_blank">
                <v-icon size="24px">mdi-instagram
                </v-icon>
            </v-btn>
            <v-btn v-if="item.twitter_profile !== null" :href="item.twitter_profile" icon target="_blank">
                <i class="bi bi-twitter-x" style="font-size: 20px;"></i>
            </v-btn>
            <v-btn v-if="item.videos_portal && item.videos_portal.length > 0" :href="item.videos_portal | youtubeVideos"
                color="red" icon target="_blank">
                <v-icon size="24px">mdi-youtube
                </v-icon>
            </v-btn>
            <v-btn v-if="item.tiktok !== null" :href="item.tiktok" color="red" icon target="_blank">
                <img src="@/assets/images/icons/ticktock.png" alt="icon" width="25" height="25">
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "CardDoctor",
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    filters: {
        youtubeVideos(videos) {
            return videos.length > 0 ? videos[0].url : "";
        },
    },
    computed: {
        formatName() {
            let name = this.item?.name ?? "";
            if (name.length > 38) {
                name = `${name.substring(0, 35)}...`;
            }
            return name;
        }
    },
    methods: {
        showProfile(idDoctor) {
            this.$router.push({
                name: "DoctorProfile",
                params: {
                    id: idDoctor,
                },
            });
            this.$emit("update-route", {id: idDoctor});
        },
    }
}
</script>

<style>
.action-socials {
    min-height: 52px;
}
.top-left {
    position: absolute;
    top: 5px;
    left: 3px;
    width: 25%;
    height: auto;
    border-radius: 2px;
}

.top-left-second {
    position: absolute;
    top: 60px;
    left: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50%;
}

.superior-derecha {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 80px;
    height: 80px;
}

.superior-derecha-segunda {
    position: absolute;
    top: 60px;
    right: -7px;
    width: 80px;
    height: 80px;
}

.superior-derecha-tercera {
    position: absolute;
    top: 150px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50%;
}

.superior-derecha-cuarta {
    position: absolute;
    top: 230px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50%;
}

.bottom-gradient {
    background-image: linear-gradient(to bottom,
            rgba(116, 148, 153, 0.29) 0%,
            rgba(15, 18, 21, 0.7) 100%);
    opacity: 1;
    background-color: transparent;
    background-blend-mode: normal;
}

#my-lax .v-parallax__content {
    background: linear-gradient(45deg, black, transparent);
}

.v-btn {
    text-transform: none !important;
}

.blend-opacity {
    opacity: 0.5;
    transition: opacity 0.4s ease-in-out;
    background-color: black;
    background-blend-mode: normal;
}

/* .blend-opacity:hover {
         opacity: 0.9;
         transition: opacity 0.4s ease-in-out;
         background-color: black;
         background-blend-mode: darken;
     }*/
.boxdivider {
    background-color: transparent;
    width: 100px;
    height: 50px;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom-width: 3px;
    border-bottom-color: blueviolet;
    border-bottom-style: solid;
    display: block;
}

.d-none {
    display: none !important;
}
</style>
